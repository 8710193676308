import { FetchClient } from "@/core/client/fetch_client"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { Token } from "@/core/models/token"
import { MutationOptions, useMutation } from "@tanstack/react-query"
import { getService } from "hooks/useStartup"

type UseSpaceTokenOptions = Omit<
  MutationOptions<Token, Error, string>,
  "mutationFn"
>

export const useSpaceToken = (options?: UseSpaceTokenOptions) => {
  const client: FetchClient = getService(FetchClient)

  return useMutation({
    ...(options ?? {}),
    mutationFn: async (spaceId: string) => {
      const response: ResponseModel = await client.postAsync(
        `/v1/spaces/${spaceId}/token`,
        {},
      )
      if (response !== null) {
        const model: Token = ResponseSerializer.deserialize(response)
        return model
      }
      return null
    },
  })
}
