import { quickactionsAtom } from "@/atoms/quickactions"
import {
  changeSpace,
  isSubspace,
  selectedSpaceAtom,
  spacesAtom,
} from "@/atoms/spaces"
import { meAtom } from "@/atoms/users"
import { Icon } from "@/features/icons/components/IconLoader"
import { ITemplate } from "@/features/templates/types"
import { NotificationSheet } from "@/modules/notifications/containers/NotificationSheet"
import { DropdownGeneric } from "@/shared/components/fields"
import { ImageWithFallback } from "@/utils/ImageWithFallback"
import { signOut } from "@/utils/api/authentication"
import { parseName } from "@/utils/helpers"
import { faPuzzle } from "@awesome.me/kit-44b29310a6/icons/classic/light"
import { faPlus } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Popover } from "antd"
import { useTheme } from "context/theme-context"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil"
import { ConnectionDot } from "../shared/components/socket/ConnectionDot"
import { createLiteTask } from "../utils/api/projects"
import Avatar from "./avatar"

type TemplateGroup = {
  uid: string
  type: "public" | "private"
}

const regex = /url\("([^"]+)"\)/

const getSymbolUrl = (url: string) => {
  const isUrl = url.includes("url")
  if (isUrl) {
    return url.replace(regex, "$1")
  }
  return url
}

export const Header = () => {
  const { t, i18n } = useTranslation("common")
  const { contents: quickActions, state } =
    useRecoilValueLoadable<ITemplate[]>(quickactionsAtom)
  const user: any = useRecoilValue(meAtom)
  const { contents: spaces, state: SpacesState } =
    useRecoilValueLoadable(spacesAtom)
  const selectedSpace = useRecoilValue(selectedSpaceAtom)
  const router = useRouter()
  const _changeSpace = useSetRecoilState(changeSpace)
  const isSupplier = useRecoilValue(isSubspace)
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const menuOpenRef = useRef(null)
  const whiteLabel = useTheme()

  const symbol = getSymbolUrl(whiteLabel?.logosymbol ?? "")

  const navigateToDashboard = () => {
    router.push(isSupplier ? "/projects" : "/")
  }

  const templates = useCallback(() => {
    if (state === "hasValue") {
      return quickActions
    }
    return []
  }, [state])

  const navigateToTaskFlow = async (template: string) => {
    const task = await createLiteTask()
    router.push({
      pathname: "/tasks/" + task.uid,
      query: {
        t: template,
      },
    })
  }

  const navigateToPublicTaskFlow = async (template: string) => {
    router.push({
      pathname: "/t/" + template,
    })
  }

  const navigateToProfile = () => {
    setMenuOpen(false)
    router.push("/settings/profile")
  }

  const menu = [
    {
      label: "Your profile",
      onClick: navigateToProfile,
    },
  ]

  if (SpacesState === "hasValue" && spaces?.length > 1) {
    menu.push({
      label: "Change space",
      onClick: () => {
        _changeSpace(true)
        setMenuOpen(false)
      },
    })
  }

  menu.push({
    label: "Sign out",
    onClick: () => {
      setMenuOpen(false)
      signOut()
    },
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const publicTemplates = new Set(
    templates()
      .filter((x) => x.is_public)
      .map((x) => ({
        uid: x.uid,
        type: "public",
      })) as TemplateGroup[],
  )
  const privateTemplates = new Set(
    templates()
      .filter((x) => x.is_quickaction && user?.is_employee)
      .map((x) => ({
        uid: x.uid,
        type: "private",
      })) as TemplateGroup[],
  )
  const onToggleLanguageClick = (newLocale: string) => {
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: newLocale })
  }

  return (
    <header className="flex h-16 w-full items-center bg-[#202020]">
      <div className="flex h-full w-16 items-center justify-center">
        <div
          onClick={navigateToDashboard}
          className="relative h-8 w-8 cursor-pointer"
        >
          <ImageWithFallback
            className="absolute"
            src={symbol}
            alt="Logo symbol"
            fill
            fallback={" "}
          />
        </div>
      </div>
      {/* <MovingButton variant="destructive" loading onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'da' : 'en')}>
				{t('ClientSide switcher')}
			</MovingButton>
			<MovingButton variant="primary" loading onClick={() => onToggleLanguageClick(i18n.language === 'en' ? 'da' : 'en')}>
				{t('ServerSide switcher')}
			</MovingButton> */}
      <Divider />
      <div className="flex flex-1 items-center justify-between px-6 py-4 pr-6">
        <div id="header-element"></div>
        <div className="min-w-fit">
          {state === "hasValue" && templates().length > 0 ? (
            <DropdownGeneric<TemplateGroup>
              collection={[
                ...Array.from(privateTemplates),
                ...Array.from(publicTemplates),
              ]}
              renderItem={({ item }) => {
                const findTemplate = templates().find((x) => x.uid === item.uid)
                if (findTemplate === undefined) return null

                if (item.type === "private" && findTemplate) {
                  return (
                    <div
                      key={"private." + findTemplate.uid}
                      onClick={() => navigateToTaskFlow(findTemplate.uid)}
                      className="cursor-pointer border-b last:border-none"
                    >
                      <p className="p-3 text-sm font-light text-gray-500 hover:text-blue-500">
                        Create{" "}
                        <span className="font-medium">{findTemplate.name}</span>
                      </p>
                    </div>
                  )
                }

                if (findTemplate.public_key === undefined) return null

                return (
                  <div
                    key={"public." + findTemplate.uid}
                    onClick={() =>
                      navigateToPublicTaskFlow(findTemplate.public_key)
                    }
                    className="cursor-pointer border-b last:border-none"
                  >
                    <p className="p-3 text-sm font-light text-gray-500 hover:text-blue-500">
                      Request{" "}
                      <span className="font-medium">{findTemplate.name}</span>
                    </p>
                  </div>
                )
              }}
              button={
                <div className="grid h-10 w-10 cursor-pointer place-content-center rounded-full bg-blue-500 text-white">
                  <FontAwesomeIcon icon={faPlus} size="lg" />
                </div>
              }
            />
          ) : isSupplier && user?.is_employee ? (
            <Popover
              content="Create tasks with the power of AI - Contact sales"
              placement="bottom"
            >
              <Link href="/sales">
                <div className="grid h-10 w-10 cursor-pointer place-content-center rounded-full bg-blue-500 text-white opacity-40">
                  <FontAwesomeIcon icon={faPlus} size="lg" />
                </div>
              </Link>
            </Popover>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Divider />
      <div className="flex w-fit items-center justify-evenly gap-4 pl-6">
        <NotificationSheet />
        <Link href="/inbox">
          <Icon icon={"faMessages"} className="text-gray-400" />
        </Link>
        {user?.is_employee && (
          <>
            <Link href="/apps">
              <FontAwesomeIcon
                className={
                  router.pathname.startsWith("/apps")
                    ? "text-blue-500"
                    : "text-gray-400"
                }
                icon={faPuzzle}
                size="lg"
              />
            </Link>
          </>
        )}
        <Divider />
      </div>

      <div className="static cursor-pointer select-none pr-6">
        <div
          className="flex items-center gap-3"
          ref={menuOpenRef}
          onMouseDown={(event) => {
            event.stopPropagation()
            setMenuOpen(!menuOpen)
          }}
        >
          <ConnectionDot>
            <Avatar email={user?.email} border={false} size={40} />
          </ConnectionDot>
          <div className="hidden flex-col items-start md:flex">
            <h6 className="text-sm font-thin text-white">{parseName(user)}</h6>
            <p className="text-xs font-thin text-gray-400">{user?.email}</p>
            <p className="text-xs font-thin text-gray-400">
              {selectedSpace?.name ?? selectedSpace?.reference}
            </p>
          </div>
        </div>
        <div
          className={`bg-custom-header-dark absolute right-0 z-50 mt-1 w-fit rounded-b px-2 py-1 text-gray-100 transition-opacity duration-200 ease-in-out md:w-1/12 ${
            menuOpen ? "block" : "hidden"
          }`}
          ref={menuRef}
        >
          {menu.map((item, i) => (
            <div
              key={i}
              onClick={item.onClick}
              className="flex w-full cursor-pointer items-center justify-between border-b py-2 transition-all first:rounded-t last:rounded-b last:border-none hover:bg-neutral-600"
            >
              <p className="px-2 text-xs font-medium">{item.label}</p>
              <Icon icon={"faChevronRight"} size="sm" className="mr-1" />
            </div>
          ))}
        </div>
      </div>
    </header>
  )
}

const Divider = () => {
  return <div className="h-full border-r border-gray-600" />
}
