import { meAtom } from "@/atoms/users"
import { Login } from "@/features/auth/components/Login"
import { SignUp } from "@/features/auth/components/SignUp"
import { SelectOrganization } from "@/modules/spaces/components/SelectOrganization"
import { SelectSpaces } from "@/modules/spaces/components/SelectSpace"
import { Space } from "@/modules/spaces/models/space"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import "dayjs/locale/da"
import "dayjs/locale/en"
import "dayjs/locale/nb"
import "dayjs/locale/sv"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { toast } from "sonner"
import { changeSpace, selectedSpaceAtom } from "../../atoms/spaces"
import RedirectProvider from "../../features/redirection/RedirectProvider"
import { useIsMounted } from "../../hooks/useIsMounted"
import { fetcher } from "../../utils/api"
import { setItem } from "../../utils/browserStorage"
import { dayjsLocaleMapper } from "../../utils/helpers"
import { Loader } from "../spinners/loader"
import { SignedIn } from "./SignedIn"
import { SignedOut } from "./SignedOut"
import { Label } from "@/core/components/Label"
import { useSpaceToken } from "@/modules/spaces/hooks/useSpaceToken"
import { Token } from "@/core/models/token"
import { useSpaceByToken } from "@/modules/spaces/hooks/useSpaceByToken"

const OrganisationProvider = ({ children }) => {
  const { t } = useTranslation("common")
  const [user, setUser] = useRecoilState(meAtom)
  const [selectedSpace, setSelectedSpace] = useRecoilState(selectedSpaceAtom)
  const [selectedOrganization, setSelectedOrganization] = useState<Space>()
  const [isChangingSpace, setIsChangingSpace] = useRecoilState(changeSpace)
  const router = useRouter()
  const mutation = useSpaceToken()
  const { data: space, loading } = useSpaceByToken()
  const { returnUrl } = router.query
  let organizationId = selectedOrganization?.uid

  if (!organizationId) {
    organizationId = selectedSpace?.isOrganization
      ? selectedSpace.uid
      : (selectedSpace?.organizationId ?? "")
  }

  const setSpace = async (space: Space) => {
    if (space) {
      const token: Token | null = await mutation.mutateAsync(space.uid)

      if (token === null) {
        toast.error("Failed to change space")
        return
      }

      setSelectedSpace(space)
      setItem("_ft_a_", token.token)
      dayjs.locale(dayjsLocaleMapper[space.locale] ?? "en")
      setIsChangingSpace(false)

      await fetcher("/me").then((data) => setUser(data))

      const pathname = (returnUrl ?? "/") as string
      window.location.assign(pathname)
    }
  }

  useEffect(() => {
    if (isChangingSpace === true) {
      const token = localStorage.getItem("_ft_a_")
      localStorage.clear()
      localStorage.setItem("_ft_a_", token)
    }
  }, [isChangingSpace])

  useEffect(() => {
    if (space !== null) {
      setSelectedSpace(space)
      setIsChangingSpace(false)
    }
  }, [space])

  useEffect(() => {
    if (user?.uid) {
      if (window._cio) {
        window._cio.identify({
          id: user.space_user_uid,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          space: user.space,
          created_at: Math.round(new Date(user.created).getTime() / 1000),
          updated_at: Math.round(new Date(user.updated).getTime() / 1000),
        })

        window._cio.page(router.asPath)
      }
    }
  }, [user])

  if (loading) return <Loader />

  if (isChangingSpace || !selectedSpace) {
    return (
      <div className="h-screen w-screen overflow-hidden bg-blue-100">
        <div className="mx-auto flex max-h-screen max-w-4xl flex-col space-y-4 py-4">
          <div>
            <Label className="mb-2 text-xs font-bold">
              {t("organization")}
            </Label>
            <SelectOrganization
              autoSelect
              selectedOrganizationId={organizationId}
              setSelectedOrganization={setSelectedOrganization}
            />
          </div>
          <div className="flex flex-1 flex-col overflow-hidden">
            <Label className="mb-2 text-xs font-bold">{t("spaces")}</Label>
            <SelectSpaces
              selectedOrganization={selectedOrganization}
              onSelect={setSpace}
            />
          </div>
          <div className="my-4 w-full border"></div>
        </div>
      </div>
    )
  }

  return children
}

const useAuthValidator = () => {
  const isMounted = useIsMounted()
  const setUser = useSetRecoilState(meAtom)
  const router = useRouter()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  const fetchMe = useCallback(async () => {
    try {
      const data = await fetcher("/me")

      if (data) {
        setIsLoggedIn(true)
        setUser(data)
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isMounted()) {
      if (router.query["_ft_c"]) {
        try {
          setItem("_ft_a_", router.query["_ft_c"].toString())

          const { _ft_c, ...rest } = router.query

          router.replace({
            pathname: router.pathname,
            query: rest,
          })
          fetchMe()
        } catch (error) {
          toast.error(error.message)
        }
      }
    }
  }, [router])

  useEffect(() => {
    if (isMounted()) {
      fetchMe()
    }

    const setLastOnline = () => {
      const dateTime = new Date().toISOString()
      localStorage.setItem("lastOnline", dateTime)
    }

    window.addEventListener("beforeunload", setLastOnline)

    return () => {
      window.removeEventListener("beforeunload", setLastOnline)
    }
  }, [isMounted, fetchMe])

  return { isLoading, isLoggedIn }
}

function AuthenticationProvider({ children }) {
  const [mounted, setMounted] = useState<boolean>(false)
  const [signedUp, setSignedUp] = useState<boolean>(false)
  const { isLoading } = useAuthValidator()
  const isMounted = useIsMounted()
  const space = useRecoilValue(selectedSpaceAtom)

  useEffect(() => {
    const fetchSignedUpStatus = async () => {
      try {
        const { signed_up } = await fetcher("/me/signed_up")
        setSignedUp(signed_up)
      } catch (error) {
        console.error("Failed to fetch signed-up status: ", error)
      }
    }

    fetchSignedUpStatus()
  }, [])

  useEffect(() => {
    if (isMounted()) {
      setMounted(true)
    }
  }, [isMounted])

  if (!mounted) return null
  if (isLoading) return <Loader />

  return (
    <RedirectProvider>
      <LocalizationProvider
        adapterLocale={dayjsLocaleMapper[space?.locale] || "en"}
        dateAdapter={AdapterDayjs}
      >
        <SignedIn>
          <OrganisationProvider>
            {signedUp === false ? <SignUp /> : children}
          </OrganisationProvider>
        </SignedIn>
        <SignedOut>
          <Login />
        </SignedOut>
      </LocalizationProvider>
    </RedirectProvider>
  )
}

export default AuthenticationProvider
