import { FetchClient } from "@/core/client/fetch_client"
import { StatusCode } from "@/core/generated/api/enums/status_code"
import { PagingModel } from "@/core/generated/api/models/paging_model"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { getService } from "hooks/useStartup"
import { useEffect, useState } from "react"
import { SpacesArgs } from "../models/args/spacesArgs"
import { Space } from "../models/space"

export const useSpaces = (request: SpacesArgs) => {
  const [data, setData] = useState<PagingModel<Space> | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [code, setCode] = useState<number>(StatusCode.Ok)
  const [message, setMessage] = useState<string>("")

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      let response: ResponseModel | null = null

      try {
        const client: FetchClient = getService(FetchClient)
        response = await client.getWithParamsAsync(
          `/v1/organizations/${request.organizationId}/spaces`,
          request,
        )
        if (response !== null) {
          const model: PagingModel<Space> | null =
            ResponseSerializer.deserialize<PagingModel<Space>>(response)
          if (model !== null) {
            setData(model)
            setCode(response.code)
          } else {
            setCode(StatusCode.NoContent)
          }
        } else {
          setCode(StatusCode.BadRequest)
        }
      } catch (err) {
        console.error(err)
        setCode(StatusCode.BadRequest)
      } finally {
        setMessage(response?.message)
        setLoading(false)
      }
    }

    getData()
  }, [request])

  return { data, loading, code, message }
}
