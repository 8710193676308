import { Loader } from "@/components/spinners/loader"
import { Icon } from "@/core/components/icon/Icon"
import { PagingArgs } from "@/core/models/args/paging"
import { useOrganizations } from "@/modules/spaces/hooks/useOrganizations"
import { Space } from "@/modules/spaces/models/space"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "@/utils/ImageWithFallback"
import { Tooltip } from "antd"
import { useIntersectionObserver } from "hooks/useIntersectionObserver"
import { useEffect, useRef, useState } from "react"

type SelectOrganizationProps = {
  selectedOrganizationId: string | null
  autoSelect?: boolean
  setSelectedOrganization: (org: Space) => void
}

export const SelectOrganization = ({
  selectedOrganizationId,
  autoSelect = false,
  setSelectedOrganization,
}: SelectOrganizationProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const entry = useIntersectionObserver(ref, {})
  // * We set pagingArgs number to 100 here, to ensure that we always get all the organizations that a user has access to
  const pagingArgs = new PagingArgs()
  pagingArgs.num = 100
  const [request, setRequest] = useState<PagingArgs>(pagingArgs)
  const { data, loading } = useOrganizations(request)
  const organizations = data?.rows ?? []
  const hasNextPage = data?.rows?.length >= data?.num

  useEffect(() => {
    if (entry?.isIntersecting && hasNextPage) {
      setRequest((prev) => ({ ...prev, num: prev.num * 2 }))
    }
  }, [entry?.isIntersecting, hasNextPage])

  useEffect(() => {
    if (autoSelect && organizations.length > 0) {
      const org = organizations.find(
        (org) => org.uid === selectedOrganizationId,
      )
      if (org) {
        setSelectedOrganization(org)
      } else {
        setSelectedOrganization(organizations[0])
      }
    }
  }, [organizations, selectedOrganizationId, autoSelect])

  return (
    <div className="no-scrollbar flex items-center gap-2 overflow-x-auto">
      {organizations.map((org) => (
        <div
          key={org.uid}
          onClick={() => setSelectedOrganization(org)}
          className="h-20 min-w-[8rem] cursor-pointer gap-2 rounded-md border-2 border-gray-200 bg-gray-200 bg-opacity-60 p-1 hover:border-gray-300 hover:bg-gray-300 aria-selected:border-2 aria-selected:border-blue-500"
          aria-selected={selectedOrganizationId === org.uid}
        >
          <Tooltip title={org.name ?? ""}>
            <div className="relative flex h-full w-full items-center justify-center">
              <ImageWithFallback
                fill
                alt={org.name ?? ""}
                fallback={<Icon icon="domain" />}
                src={ORGANISATION_AVATERS(org.uid)}
              />
            </div>
          </Tooltip>
        </div>
      ))}
      {loading && <Loader />}
      <div ref={ref} />
    </div>
  )
}
