import { Loader } from "@/components/spinners/loader"
import { Icon } from "@/core/components/icon/Icon"
import { useSpaces } from "@/modules/spaces/hooks/useSpaces"
import { Space } from "@/modules/spaces/models/space"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "@/utils/ImageWithFallback"
import { useIntersectionObserver } from "hooks/useIntersectionObserver"
import { useEffect, useRef, useState } from "react"
import { SpacesArgs } from "../models/args/spacesArgs"
import { orderBy } from "lodash"

type SelectSpacesProps = {
  selectedOrganization: Space
  onSelect: (space: Space) => void
}

export const SelectSpaces = ({
  selectedOrganization,
  onSelect,
}: SelectSpacesProps) => {
  const [request, setRequest] = useState<SpacesArgs>(new SpacesArgs())
  const { data, loading } = useSpaces(request)
  const ref = useRef<HTMLDivElement>(null)
  const entry = useIntersectionObserver(ref, {})
  const spaces: Space[] = data?.rows ?? []
  const hasNextPage = data?.rows?.length >= request?.num
  const sortedSpaces = orderBy(spaces, (s) => s.isOrganization, "desc")

  useEffect(() => {
    if (selectedOrganization) {
      setRequest((prev) => ({
        ...prev,
        organizationId: selectedOrganization.uid,
      }))
    }
  }, [selectedOrganization?.uid])

  useEffect(() => {
    if (entry?.isIntersecting && hasNextPage) {
      setRequest((prev) => ({ ...prev, num: prev.num * 2 }))
    }
  }, [entry?.isIntersecting, hasNextPage])

  return (
    <div className="grid grid-cols-2 gap-2 overflow-y-auto">
      {sortedSpaces.map((space) => (
        <div
          key={space.uid}
          onClick={() => onSelect(space)}
          className="flex h-10 w-full cursor-pointer items-center gap-4 rounded-md bg-gray-200 bg-opacity-60 px-3 py-8 hover:bg-gray-300"
        >
          <div className="max-h-8 w-[110px] items-center">
            <ImageWithFallback
              width={0}
              height={0}
              alt={space.name ?? ""}
              fallback={<Icon icon="domain" />}
              src={ORGANISATION_AVATERS(space.uid)}
            />
          </div>
          <div className="flex-1 text-sm font-medium">
            {!!space.name ? space.name : space.reference}
          </div>
        </div>
      ))}
      {loading && <Loader />}
      <div ref={ref} />
    </div>
  )
}
