import { selectedSpaceAtom } from "@/atoms/spaces"
import { cn } from "@/utils/helpers"
import { useCallback, useEffect } from "react"
import { isSupportedCountry } from "react-phone-number-input"
import {
  default as ReactPhoneInput,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { useRecoilValueLoadable } from "recoil"

interface IPhoneInput {
  label?: string
  value: any
  onChange?: (value: string) => void
  isValid?: boolean
  setIsValid?: (isValid: boolean) => void
  disabled?: boolean
  className?: string
}

/**
 * @deprecated - This function should NOT be used. Use app/core/components/phoneInput/PhoneInput.tsx instead.
 */
export const PhoneInput = ({
  label,
  value,
  onChange,
  isValid,
  setIsValid,
  disabled,
  className,
}: IPhoneInput) => {
  const browserLocale = (
    navigator.language || navigator.languages[0]
  ).substring(0, 2)
  const space = useRecoilValueLoadable(selectedSpaceAtom)
  const { locale } =
    space.state === "hasValue"
      ? space.contents
      : { locale: browserLocale.split("-")[0] }
  const countryCode = isSupportedCountry(locale) ? locale : "DK"

  const errorMessage =
    setIsValid && !isValid ? (
      <p className="text-xs text-red-500">Invalid phone number</p>
    ) : null

  const checkPhoneNumber = useCallback(() => {
    const stringValue = String(value)
    if (stringValue && stringValue !== "") {
      setIsValid(
        isPossiblePhoneNumber(stringValue) || isValidPhoneNumber(stringValue),
      )
    } else {
      setIsValid(true)
    }
  }, [value])

  useEffect(() => {
    if (!setIsValid) return
    checkPhoneNumber()
  }, [value])

  if (!isValidPhoneNumber(String(value), countryCode)) {
    if (typeof value !== "string") {
      value = String(value).replace(/[^0-9]/g, "")
    } else {
      value = value.replace(/[^0-9]/g, "")
    }
  }

  return (
    <div
      className={cn(
        "border-custom-gray group relative flex min-h-[3rem] w-full flex-col gap-0.5 rounded-xl border bg-white px-4 py-3 ring-inset group-focus:ring-2",
        className,
      )}
    >
      <span
        className={cn(
          "text-custom-gray-dark pointer-events-none bg-inherit text-xs font-light leading-none transition-all",
        )}
      >
        {label}
      </span>
      <ReactPhoneInput
        className={cn(
          "disabled:text-custom-gray w-full select-none border-none bg-white text-sm text-gray-500 placeholder-gray-500 outline-none focus:bg-white",
          errorMessage && "border-red-500",
        )}
        disabled={disabled}
        value={String(value)}
        onChange={onChange}
        country={locale}
      />
    </div>
  )
}
