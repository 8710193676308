import { Serializable } from "@/core/api/decorators/serializable_decorator"
import { CreateParty } from "./createParty"
import { StringUtil } from "@/core/utils/string_util"
import { ContractStatus } from "../constants/contractStatus"

@Serializable
export class CreateContract {
  content: string = ""
  parties: CreateParty[] = []
  templateId: string = ""
  taskId: string = ""
  customerId: string = ""
  status: ContractStatus = ContractStatus.Draft
  properties: Record<string, any> = {}

  constructor(
    data: {
      content: string
      parties: CreateParty[]
      templateId: string
      taskId: string | undefined
      customerId: string | undefined
      status: ContractStatus
      properties: Record<string, any>
    } | null = null,
  ) {
    if (data) {
      this.content = data.content
      this.parties = data.parties
      this.templateId = StringUtil.getSafeString(data.templateId)
      this.taskId = StringUtil.getSafeString(data.taskId)
      this.customerId = StringUtil.getSafeString(data.customerId)
      this.status = data.status
      this.properties = data.properties
    }
  }
}
